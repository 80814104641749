import Certifications from '@components/Certifications'
import { ArrowLeft, BigLemonLogo } from '@components/IconComponent'
import SeoComponent from '@components/SeoComponent'
import SpacerComponent from '@components/SpacerComponent'
import { Caption, Paragraph, Title1 } from '@components/Typography'
import useIsTablet from '@hooks/useIsTablet'
import useSetBackgroundColor from '@hooks/useSetBackgroundColor'
import { Widget } from '@typeform/embed-react'
import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'

const Quote = () => {
  const { ref } = useSetBackgroundColor()
  const isTablet = useIsTablet()

  return (
    <Container>
      <SeoComponent
        title='Get a Quote | Big Lemon'
        description='Have an idea or project you’re looking to get off the ground, improve, or launch? Get in touch and we’ll create your quote.'
      />
      <Section>
        <BackSection>
          <ArrowLeft />
          <Link to='/'>
            <Caption>Back to website</Caption>
          </Link>
        </BackSection>
        <div>
          <SpacerComponent bottom='lg' />
          <LogoContainer>
            <Link to='/'>
              <BigLemonLogo />
            </Link>
          </LogoContainer>
          <SpacerComponent bottom='lg' />
          <Title1 ref={ref}>Get a quote</Title1>
          <SpacerComponent bottom='xs' />

          <Paragraph size='md'>
            Have a project you’re looking to get off the ground, improve, or
            launch? Want to chat to someone about digital product strategy?
          </Paragraph>

          <Paragraph size='md'>
            No problem, fill out the form and we’ll be in touch shortly with
            your quote.
          </Paragraph>
        </div>
        <SpacerComponent bottom='lg' />
        <Certifications />
      </Section>
      <FormContainer isTablet={isTablet}>
        <Widget id='AnfXZgxd' style={{ width: '100%', height: '100%' }} />
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-width: 50%;
  padding-left: ${props => props.theme.spacing.xl2};
  padding-right: ${props => props.theme.spacing.xl2};
  padding-top: ${props => props.theme.spacing.xl};
  padding-bottom: ${props => props.theme.spacing.xl};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: ${props => props.theme.spacing.xl};
    padding-bottom: ${props => props.theme.spacing.xl};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: ${props => props.theme.spacing.xl};
    padding-bottom: ${props => props.theme.spacing.xl};
  }
`

const BackSection = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;
  max-height: 20px;
  a {
    text-decoration: none;
  }
  svg {
    margin-right: ${props => props.theme.spacing.xs};
    width: 20px;
  }
`

const LogoContainer = styled.div`
  max-width: 154px;
`

const FormContainer = styled.div<{ isTablet: boolean }>`
  min-width: 500px;
  width: 50%;
  flex: 1;
  min-height: 100vh;
`

export default Quote
